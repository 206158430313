<template>
  <v-container height="best_height" fill-height>
    <v-form v-model="valid" ref="issueForm" @submit="send_order">
      <v-row>
        <v-col class="text-center" cols="12" md="6">
          <h3>{{ this.store.name }}</h3>

          {{ this.store.identification_id }}<br />
          {{ this.store.address }}<br />
          {{ this.store.city + ", " + this.store.province }}<br />
          {{ this.store.mobile }}<br />
          <span class="totals">Orden No. {{ this.header.doc_code }}</span>
        </v-col>
        <v-col cols="12" md="6">
          <h3>{{ this.profile.name }}</h3>
          {{ this.profile.address }}<br />
          {{ this.profile.city + ", " + this.profile.province }}<br />
          {{ this.profile.mobile }}<br />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="header[$vuetify.breakpoint.xsOnly]"
            :items="cart"
            :items-per-page="-1"
            item-key="index"
            hide-default-footer
            sort-by="name"
            mobile-breakpoint="0"
            class="table-cursor"
            loading-text="Cargando ..."
            dense
          >
            <template v-slot:top>
              <h1>Orden de Compra</h1>
            </template>
            <template v-slot:[`item.product_name`]="{ item }" class="pa-0 ma-0">
              <div @click="editItm(item)">
                <b class="text-uppercase">{{ item.product_name }}</b>
                <br />
                Pack : {{ item.pack }} X {{ item.size + " " + item.uom }}

                <br />
                <span v-if="item.shipping_time > 1">
                  Tiempo de entrega: {{ item.shipping_time }} días hábiles.
                </span>
                <span v-else>
                  Tiempo de entrega: {{ item.shipping_time }} día hábil.
                </span>
                <span v-if="item.note" v-html="item.note" />
              </div>
            </template>
            <template v-slot:[`item.price`]="{ item }" class="text-right">
              <span class="text-decoration-line-through red--text" v-if="item.discount"
                >{{
                  "$" +
                  (
                    parseFloat(item.price) *
                    parseFloat(item.qty) *
                    parseFloat(item.pack)
                  ).toLocaleString()
                }}<br
              /></span>
              <b>{{ "$" + parseFloat(item.tItem).toLocaleString() }}</b>
              <v-spacer></v-spacer>
              <v-btn icon x-small @click="deleteItem(item)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <template slot="body.append" class="totals">
              <tr>
                <th class="text-center">
                  {{ totals.qty.toLocaleString(2) }}
                </th>
                <td v-if="!$vuetify.breakpoint.xsOnly"></td>
                <td v-if="!$vuetify.breakpoint.xsOnly"></td>
                <th class="text-right totals">Sub-total</th>
                <th class="text-right totals">
                  {{ this.totals.subtotal.toLocaleString() }}
                </th>
              </tr>
              <tr>
                <th></th>
                <td v-if="!$vuetify.breakpoint.xsOnly"></td>
                <td v-if="!$vuetify.breakpoint.xsOnly"></td>
                <th class="text-right totals">Impuesto</th>
                <th class="text-right totals">
                  {{ this.totals.tax.toLocaleString() }}
                </th>
              </tr>
              <tr>
                <th></th>
                <td v-if="!$vuetify.breakpoint.xsOnly"></td>
                <td v-if="!$vuetify.breakpoint.xsOnly"></td>
                <th class="text-right totals">Totales</th>
                <th class="text-right totals">
                  {{ this.totals.total.toLocaleString() }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-bottom-navigation
        :app="$vuetify.breakpoint.xsOnly"
        v-if="$store.getters.profile.code"
        class="elevation-0 mt-6"
      >
        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
        <v-select
          v-model="branch"
          hide-details="auto"
          :rules="f_required"
          item-value="value"
          :items="branches"
          label="Dirección de Entrega"
          return-object
          outlined
        >
          <template slot="selection" slot-scope="data">
            <!-- HTML that describe how select should render selected items -->
            {{ data.item.name }} - {{ data.item.address }}
          </template>
          <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ data.item.name }} - {{ data.item.address }}
          </template>
        </v-select>

        <v-btn
          large
          class="btn-large"
          color="success"
          type="submit"
          :disabled="loading_status"
          dark
        >
          Enviar Orden
        </v-btn>
      </v-bottom-navigation>

      <v-bottom-navigation
        :app="$vuetify.breakpoint.xsOnly"
        v-if="!$store.getters.profile.code"
      >
        <v-spacer></v-spacer>
        <v-btn rounded dark to="Registration"> Registrarse </v-btn>
        <v-spacer></v-spacer>
      </v-bottom-navigation>
    </v-form>
  </v-container>
</template>

<script>
import { webserver, getIssueDate } from "../../services/webserver.js";
import createParty from "../../utils/createParty.js";
export default {
  name: "Shoppingcart",
  components: {},
  props: {},
  data() {
    return {
      cart: [],
      store: createParty(),
      profile: createParty(),
      loading_status: false,
      header: {
        false: [
          {
            text: "Cant.",
            align: "center",
            value: "qty",
            width: 2,
          },
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Tamaño",
            align: "start",
            sortable: true,
            value: "size",
            dataType: "text",
          },
          {
            text: "UOM",
            align: "start",
            sortable: true,
            value: "uom",
            dataType: "text",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "price",
          },
        ],
        true: [
          {
            text: "Cant.",
            align: "center",
            value: "qty",
            width: 2,
          },
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "price",
          },
        ],
      },
      header_selected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
        },
      ],
      headerSelected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
      ],
      sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
      f_required: [(v) => !!v || "Requerido"],
      f_valid_dates: [
        (v) => !!v || "El código es requerido",
        (v) => v >= getIssueDate(-1) || "Fecha no valida",
        (v) =>
          v <= getIssueDate(-15) || "Máximo 15 días de antelación : " + getIssueDate(-15),
      ],
      delivery_issue: { issueDate: getIssueDate(-1), issueTime: null },
      delivery_times: [
        "6:00 a.m. - 10:00 a.m.",
        "10:00 a.m. - 02:00 p.m.",
        "02:00 a.m. - 06:00 p.m.",
      ],
      valid: false,
      best_height: "100%",
      best_widht: "500",
      branches: [],
      branch: null,
      totals: { qty: 0, total: 0, subtotal: 0, tax: 0, paid: 0, unpaid: 0 },
    };
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_widht = window.innerWidth;
      }
    },
    editItm(e) {
      var index = this.cart.indexOf(e);
      this.$router.push({
        name: "Product",
        params: { item: { ...e }, addItm: false, idx: index },
      });
    },
    send_order(e) {
      e.preventDefault();
      this.valid = this.$refs.issueForm.validate();
      if (this.valid) {
        var perfil = { ...this.$store.getters.profile };

        perfil.name = this.branch.name;
        perfil.address = this.branch.address;
        perfil.city = this.branch.city;

        this.updateTotals();
        this.cart.forEach((itm) => {
          delete itm.percent;
        });

        var order = {
          pos: this.$store.getters.company,
          party: perfil,
          total: this.sc.total,
          items: this.cart,
        };

        order.total = this.sc.total;

        order.qty = parseFloat(this.totals.qty);
        order.total = parseFloat(this.totals.total);
        order.tax = parseFloat(this.totals.tax);
        order.subtotal = parseFloat(this.totals.subtotal);

        order.account = this.$store.getters.company.account;
        order.doc_type = "OR";
        order.store = this.$store.getters.company.code;
        order.userId = this.$store.getters.profile.code;
        order.agent = "WEB";
        order.status = "PENDIENTE";
        order.delivery_date = this.delivery_issue.issueDate;
        order.delivery_time = this.delivery_issue.issueTime;
        order.items = this.cart;

        this.loading_status = true;
        webserver("put_doc", order, (data) => {
          this.loading_status = false;
          if (data.header) {
            this.cart = [];
            localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
            this.$emit("resetCart", true);
          }
          this.$router.push("Checkout/?did=" + data.header.doc_code);
        });
      }
    },
    deleteItem(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
      this.$emit("update", true);
      this.updateTotals();
    },
    //   var itms = this.cart;
    //   this.sc = { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 };
    //   itms.forEach((itm) => {
    //     itm.qty = parseFloat(itm.qty);
    //     itm.pack = parseFloat(itm.pack);
    //     itm.cost = parseFloat(itm.cost);
    //     itm.price = parseFloat(itm.price);
    //     itm.discount = parseFloat(itm.discount);
    //     var tax_value = parseFloat(itm.tax) / 100 + 1;

    //     itm.discount_value = (
    //       (parseFloat(itm.price) * parseFloat(itm.discount)) /
    //       100
    //     ).toFixed(2);

    //     itm.price_value = (
    //       parseFloat(itm.price) - parseFloat(itm.discount_value)
    //     ).toFixed(2);

    //     itm.price_amount = Math.round(parseFloat(itm.price_value) / tax_value).toFixed(2);

    //     itm.tax_value = Math.round(
    //       (parseFloat(itm.price_amount) *
    //         parseFloat(itm.qty) *
    //         parseFloat(itm.pack) *
    //         itm.tax) /
    //         100
    //     ).toFixed(2);

    //     itm.tItem = (
    //       parseFloat(itm.price_amount) * parseFloat(itm.qty) * parseFloat(itm.pack) +
    //       parseFloat(itm.tax_value)
    //     ).toFixed(2);

    //     this.sc.subtotal += parseFloat(itm.tItem) - parseFloat(itm.tax_value);
    //     this.sc.tax += parseFloat(itm.tax_value) * parseFloat(itm.qty);
    //     this.sc.total += parseFloat(itm.tItem) * parseFloat(itm.qty);
    //   });
    // },
    updateTotals() {
      this.totals.qty = 0;
      this.totals.subtotal = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;
      this.totals.costs = 0;
      var itms = this.cart;

      itms.forEach((itm) => {
        itm.qty = parseFloat(itm.qty);
        itm.pack = parseFloat(itm.pack);
        itm.cost = parseFloat(itm.cost);
        itm.price = parseFloat(itm.price);
        itm.discount = parseFloat(itm.discount);
        itm.discount_value = parseFloat(itm.discount_value);
        itm.tax_value = parseFloat(itm.tax_value);
        itm.tItem =
          (parseFloat(itm.price) - parseFloat(itm.discount_value)) * parseFloat(itm.qty);
        this.totals.qty += parseFloat(itm.qty);
        this.totals.subtotal += parseFloat(itm.tax_base) * parseFloat(itm.qty);
        this.totals.total += parseFloat(itm.tItem);
        this.totals.tax += itm.tax_value * parseFloat(itm.qty);
      });
      this.totals.unpaid = this.totals.total - this.totals.paid;
    },
    selecttype(e) {
      return e[0].unique;
    },
    sumField(fld, key) {
      return this[fld].reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    this.store = this.$store.getters.company;
    console.log("STORE", this.store);
    // this.store = JSON.parse(Buffer.from(localStorage.company, "base64"));

    // var company = window.localStorage.getItem("company");
    // if (company) {
    //   this.store = JSON.parse(
    //     this.CryptoJS.AES.decrypt(company, process.env.VUE_APP_QR4).toString(
    //       this.CryptoJS.enc.Utf8
    //     )
    //   );
    //   console.log(this.store);
    // }

    var perfil = window.localStorage.getItem("profile");
    if (perfil) {
      var itm = JSON.parse(
        this.CryptoJS.AES.decrypt(perfil, process.env.VUE_APP_QR4).toString(
          this.CryptoJS.enc.Utf8
        )
      );
      if (itm.mobile) {
        this.$store.dispatch("setProfile", itm);
        this.profile = itm;
      }
      this.branches.push({
        name: itm.name,
        address: itm.address,
        city: itm.city,
      });
    }

    this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));

    if (this.$store.getters.profile.shipping_address) {
      this.branches.push.apply(
        this.branches,
        JSON.parse(this.$store.getters.profile.shipping_address)
      );
    }
    this.updateTotals();
  },
  watch: {
    cart: function () {
      this.updateTotals();
    },
    "$store.getters.company"(newVal) {
      if (newVal) {
        this.store = newVal;
      }
    },
  },
};
</script>

<style>
.btn-large {
  color: white !important;
  font-weight: bold !important;
  font-size: large !important;
}
</style>
